import React, { useState, useEffect } from 'react'
import NavLinks from './NavLink' // Assuming this is your NavLinks component
import { Link, Navigate, useNavigate } from 'react-router-dom'
import AVATAR from '../assets/Favicon/avatar.svg'
import {
  TbDeviceDesktopAnalytics,
  TbSettings,
  TbUsers,
  TbCreditCard,
  TbLogout,
} from 'react-icons/tb'
import { FaUsersLine } from 'react-icons/fa6'
import { FaRegUserCircle } from 'react-icons/fa'
import AddTeamWorkSpace from '../pages/AddTeamWorkSpace/AddTeamWorkSpace'
import { Col } from 'react-bootstrap'

const NavLinksContainer = ({ chatLog, setChatLog }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [showMenu, setShowMenu] = useState(false)
  useEffect(() => {
    const username = localStorage.getItem('Full_name')
    const avatar = localStorage.getItem('avatar')
    const plan = localStorage.getItem('plan')
    const email = localStorage.getItem('email')
    const role = localStorage.getItem('role')

    // @ts-ignore
    setUser({ username, avatar, plan, email, role })
  }, [])

  const handleLogout = () => {
    localStorage.clear()
    window.location.reload()
  }

  // const handleSettings = () => {
  //   navigate("/setting");
  // };

  const handleAddTeam = () => {
    navigate('/add-team-workspace')
  }
  const handleAddTeamProtection = (e) => {
    e.preventDefault()
    navigate('/add-team-workspace', {
      state: { state: 'add-team-protection' },
      replace: true,
    })
    // return <Navigate to={'add-team-workspace'} state={"add-team-protection"} replace={true} />
  }
  // const handleProtection = () => {
  //   navigate("/add-team-workspace", {
  //     state: "add-protection",
  //     replace: true,
  //   });
  // };

  const handleMembers = () => {
    navigate('/add-team-workspace')
  }

  const handleBilling = () => {
    navigate('/billing')
  }

  const handleAnalytics = () => {
    navigate('/analytics')
  }

  // const toggleMenu = () => {
  //   setShowMenu(!showMenu);
  // };

  // Render nothing if user is not yet initialized
  if (!user) {
    return null
  }

  return (
    <div className='navContainer' onMouseLeave={() => setShowMenu(false)}>
      {
        // @ts-ignore
        user.role === 'admin' && (
          <NavLinks
            svg={<FaUsersLine />}
            text='Add Team Members'
            onClick={handleAddTeam}
            link={undefined}
          />
        )
      }
      {
        // @ts-ignore

        user.role === 'member' && user.plan === 'plus' && (
          <NavLinks
            svg={<FaUsersLine />}
            text='Add Team Protection'
            onClick={(e) => handleAddTeamProtection(e)}
            link={undefined}
          />
        )
      }
      {/* {user.plan === "none" && (
        <NavLinks
          svg={<FaUsersLine />}
          text="Add Protection"
          onClick={handleProtection}
          link={undefined}
        />
      )} */}
      <div
        className={`userSection ${showMenu ? 'active' : ''}`}
        // onClick={toggleMenu}
        onMouseEnter={() => setShowMenu(true)}
      >
        <img
          style={{
            height: '30px',
            width: '30px',
          }}
          src={AVATAR}
          alt='User Avatar'
          className='avatar'
        />
        <div className='d-flex flex-column'>
          <span className='username' onClick={() => setShowMenu(false)}>
            {
              // @ts-ignore
              user?.username
            }
          </span>
          <span className='username' onClick={() => setShowMenu(false)}>
            {
              // @ts-ignore
              user?.email
            }
          </span>
        </div>


        {showMenu && (
          <div className='navMenu'>
            <NavLinks
              svg={<FaRegUserCircle />}
              // @ts-ignore
              text={user.email}
              link='https://help.openai.com/en/collections/3742473-chatgpt'
            // onClick={()=>navigate('/select-organization')} // onClick={handleSettings}
            />

            {
              // @ts-ignore

              user.role === 'admin' && (
                <>
                  <NavLinks
                    svg={<TbUsers />}
                    text='Members'
                    onClick={handleMembers}
                    link={undefined}
                  />
                  <NavLinks
                    svg={<TbDeviceDesktopAnalytics />}
                    text='Analytics'
                    onClick={handleAnalytics}
                    link={undefined}
                  />
                  <NavLinks
                    svg={<TbCreditCard />}
                    text='Billing'
                    onClick={handleBilling}
                    link={undefined}
                  />
                </>
              )
            }
            {user.plan === 'plus' && (
              <>
                <NavLinks
                  svg={<TbCreditCard />}
                  text='Billing'
                  onClick={handleBilling}
                  link={undefined}
                />
              </>
            )}

            <NavLinks
              svg={<TbLogout />}
              text='Log out'
              onClick={handleLogout}
              link='/'
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default NavLinksContainer
