import React from "react";
import BotLogo from "../assets/Favicon/128x128.svg";
import Logo from "../assets/Logo/Logo_hor_green.svg";

const IntroSection = () => {
  return (
    <>
      
      <div id="introsection">
        <h1 class="gradient-text">Phishing or not? Check it here:</h1>
      </div>
    </>
  );
};

export default IntroSection;
