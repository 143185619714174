import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import NavLinksContainer from "./NavLinksContainer";
import NavPrompt from "./NavPrompt";
import NewChat from "./NewChat";
import dayjs from "dayjs";
import { IoMdArrowRoundBack } from "react-icons/io";
import Logo from "../assets/Logo/128x128.svg";
import { listTeamMembers } from "../utils/api";
import { useData } from "../utils/DataContext";

// Helper functions to categorize chat entries
const isToday = (date) => dayjs(date).isSame(dayjs(), "day");
const isYesterday = (date) => dayjs(date).isAfter(dayjs().subtract(1, "days"));
const isWithinLast7Days = (date) =>
  dayjs(date).isAfter(dayjs().subtract(7, "days"));
const isWithinLast30Days = (date) =>
  dayjs(date).isAfter(dayjs().subtract(30, "days"));

const categorizeChatLog = (chatLog) => {
  if (!Array.isArray(chatLog)) {
    return {
      today: [],
      yesterday: [],
      last7Days: [],
      last30Days: [],
      older: [],
    };
  }

  const today = [];
  const yesterday = [];
  const last7Days = [];
  const last30Days = [];
  const older = [];

  chatLog.forEach((data) => {
    const createdAt = data.createdAt;

    if (isToday(createdAt)) {
      today.push(data);
    } else if (isYesterday(createdAt)) {
      yesterday.push(data);
    } else if (isWithinLast7Days(createdAt)) {
      last7Days.push(data);
    } else if (isWithinLast30Days(createdAt)) {
      last30Days.push(data);
    } else {
      older.push(data);
    }
  });

  return { today, yesterday, last7Days, last30Days, older };
};

const NavContent = ({ chatLog, setChatLog, setShowMenu }) => {
  const [filteredMembers, setFilteredMembers] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const plan = localStorage.getItem("plan");
  const [activeKey, setActiveKey] = useState(false);
  const [resp, setResp] = useState(false);
  const { listMembers } = useData();

  useEffect(() => {
    // listTeamMembers().then((res) => {
    const fetchdata = async () => {
      try {
        await setFilteredMembers(listMembers?.data?.teamMembers);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
    // });
  }, [listMembers]);
  const handleGoToChatClick = () => {
    navigate("/dashboard");
  };

  const categorizedChatLog = categorizeChatLog(chatLog?.urls || []);

  const shouldBlur =
    location.pathname === "/add-team-workspace" ||
    location.pathname === "/setting" ||
    location.pathname === "/billing";

  return (
    <>
      <div
        style={
          {
            width: "10%",
            height: "5%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "7%"
          }
        }
      >
        {shouldBlur && (
          <button
            onClick={handleGoToChatClick}
            style={{
              position: "absolute",
              // top: "8%",
              // left:"1%",
              zIndex: 10,
              width: "90%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              backgroundColor: "black",
              border: "1px solid #333",
              color: "white",
              padding: "10px",
              borderRadius: "15px",
              cursor: "pointer",
            }}
          >
            <IoMdArrowRoundBack />
            Go back to Chat
          </button>
        )}
      </div>

      <div
        className="navItems"
        style={{
          filter: shouldBlur ? "blur(10px)" : "none",
          cursor: shouldBlur ? "not-allowed" : "pointer",
          position: "relative",
          transition: "filter 0.3s",
          width: "100%",
        }}
      >
        <div className="d-flex flex-row justify-content-center py-2">
          <img className="side-logo" src={Logo} alt="" />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="custom-dropdown-toggle sideMenuButton py-2 mb-2"
            onClick={()=> setChatLog('')}
            style={{
              backgroundColor: "rgba(73, 84, 55, 0)",
              color: "#bbff4b",
              width: "90%",
              borderColor: "#bbff4b",
              borderRadius: '30px'
            }}
          >
            New Chat
          </button>
        </div>
        {plan !== "plus" && (
          <NewChat setChatLog={setChatLog} setShowMenu={setShowMenu} />
        )}

        <div className="navPromptWrapper">
          {categorizedChatLog.today.length > 0 && (
            <>
              <h6>Today</h6>
              {categorizedChatLog.today.reverse().map((data) => (
                <div
                  key={`${data._id}`}
                  style={{
                    color: activeKey === data._id && resp ? "#bbff33" : "white",
                    backgroundColor:
                      activeKey === data._id && resp ? "#454550" : "",
                    // borderRadius: "15px",
                  }}
                  onClick={() => {
                    setActiveKey(data._id);
                  }}
                >
                  <NavPrompt
                    setSelectedTab={(e) => setResp(e)}
                    originalUrl={data.originalUrl}
                    urlId={data._id}
                    setShowMenu={setShowMenu}
                    filteredMembers={filteredMembers}
                    sharedWith={data.sharedWith ?? []}
                  />
                </div>
              ))}
            </>
          )}
          {categorizedChatLog.yesterday.length > 0 && (
            <>
              <h6 style={{ fontSize: '12px', paddingLeft: '10px' }}>Yesterday</h6>
              {categorizedChatLog.yesterday.reverse().map((data) => (
                <div
                  key={`${data._id}`}
                  style={{
                    color: activeKey === data._id && resp ? "#bbff33" : "white",
                    backgroundColor:
                      activeKey === data._id && resp ? "#454550" : "",
                    // borderRadius: "15px",
                  }}
                  onClick={() => {
                    setActiveKey(data._id);
                  }}
                >
                  <NavPrompt
                    originalUrl={data.originalUrl}
                    setSelectedTab={(e) => setResp(e)}
                    urlId={data._id}
                    filteredMembers={filteredMembers}
                    setShowMenu={setShowMenu}
                    sharedWith={data.sharedWith ?? []}
                  />
                </div>
              ))}
            </>
          )}
          {categorizedChatLog.last7Days.length > 0 && (
            <div>
              <h6 style={{ fontSize: '12px', paddingLeft: '10px' }}>Last 7 Days</h6>
              {categorizedChatLog.last7Days.reverse().map((data) => (
                <div
                  key={`${data._id}`}
                  style={{
                    color: activeKey === data._id && resp ? "#bbff33" : "white",
                    backgroundColor:
                      activeKey === data._id && resp ? "#454550" : "",
                    // borderRadius: "15px",
                  }}
                  name={data._id}
                  onClick={() => {
                    setActiveKey(data._id);
                  }}
                >
                  <NavPrompt
                    originalUrl={data.originalUrl}
                    setSelectedTab={(e) => setResp(e)}
                    urlId={data._id}
                    filteredMembers={filteredMembers}
                    setShowMenu={setShowMenu}
                    sharedWith={data.sharedWith ?? []}
                  />
                </div>
              ))}
            </div>
          )}
          {categorizedChatLog.last30Days.length > 0 && (
            <>
              <h6 style={{ fontSize: '12px', paddingLeft: '10px' }}>Last 30 Days</h6>
              {categorizedChatLog.last30Days.reverse().map((data) => (
                <div
                  key={`${data._id}`}
                  style={{
                    color: activeKey === data._id && resp ? "#000" : "white",
                    backgroundColor:
                      activeKey === data._id && resp ? "#bbff4b33" : "",
                    // borderRadius: "15px",
                  }}
                  name={data._id}
                  onClick={() => {
                    setActiveKey(data._id);
                  }}
                >
                  <NavPrompt
                    originalUrl={data.originalUrl}
                    setSelectedTab={(e) => setResp(e)}
                    urlId={data._id}
                    filteredMembers={filteredMembers}
                    setShowMenu={setShowMenu}
                    sharedWith={data.sharedWith ?? []}
                  />
                </div>
              ))}
            </>
          )}
          {categorizedChatLog.older.length > 0 && (
            <>
              <h6 style={{ fontSize: '12px', paddingLeft: '10px' }}>Older</h6>
              {categorizedChatLog.older.reverse().map((data) => (
                <div
                  key={`${data._id}`}
                  style={{
                    color: activeKey === data._id && resp ? "#bbff33" : "white",
                    backgroundColor:
                      activeKey === data._id && resp ? "#454550" : "",
                    // borderRadius: "15px",
                  }}
                  name={data._id}
                  onClick={() => {
                    setActiveKey(data._id);
                  }}
                >
                  <NavPrompt
                    originalUrl={data.originalUrl}
                    setSelectedTab={(e) => setResp(e)}
                    urlId={data._id}
                    key={data._id}
                    filteredMembers={filteredMembers}
                    setShowMenu={setShowMenu}
                    sharedWith={data.sharedWith ?? []}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <NavLinksContainer chatLog={chatLog} setChatLog={setChatLog} />
    </>
  );
};

export default NavContent;
