import React, { useContext, useEffect, useRef, useState } from "react";
import Avatar from "../../components/Avatar";
import IntroSection from "../../components/IntroSection";
import Loading from "../../components/Loading";
import NavContent from "../../components/NavContent";
import { FaArrowUp } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import { API_BASE_URL } from "../../api.config";
import AVATAR from "../../assets/Favicon/avatar.svg";
import { Context } from "../../Context/ContexApi";
import { listUrls, localize, readUser, refresh } from "../../utils/api";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import UpgradePlan from "../upgradePlan/UpgradePlan";
import PricingBox from "../../components/PricingBox";
import { useData } from "../../utils/DataContext";

const Home = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [responseFromAPI, setResponseFromAPI] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [UserData, setUserData] = useState([]);
  const organizationId = localStorage.getItem("organization_id");
  const chatLogEndRef = useRef(null);
  const textareaRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldLogOut, setShouldLogOut] = useState(false);
  const [errorRedirect, setErrorRedirect] = useState("");
  const [_error, setError] = useState("");
  const [textareaHeight, setTextareaHeight] = useState(0);

  const isPlanNone = localStorage.getItem("plan") === "none";
  // @ts-ignore
  const { chatLog, setChatLog } = useContext(Context);

  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem("user_id");
      const token = localStorage.getItem("token");

      if (userId && token) {
        const response = await listUrls(userId);
        console.log(response);
        if (response) {
          const data = response.data;
          setUserData(data);
        } else {
          console.error("Failed to fetch user data");
        }
      } else {
        console.error("User ID or token not found in localStorage");
      }
    } catch (error) {
      setErrorRedirect(error.response.data.error);
      setError(error.response.data.error);
      if (
        _error === "You are no longer part of the current organization" ||
        _error === "Token expired"
      ) {
        setShouldLogOut(true);
      } else if (_error === "Error checking Membership.") {
        navigate("/select-organization");
      }
    }
  };
  const clearTextarea = () => {
    setInputPrompt('');
    textareaRef.current.style.height = "40px";

    const chatArea = chatAreaRef.current;

    chatArea.style.height = '90%'
  };

  const inputAreaRef = useRef(null);

  const chatAreaRef = useRef(null);

  useEffect(() => {
    const inputAre = inputAreaRef.current;
    if (chatLog.length === 0) {
      if (inputAre) {
        inputAre.style.position = 'relative';
        inputAre.style.width = '100%';
      }
    } else {
      if (inputAre) {
        inputAre.style.position = 'absolute';
        inputAre.style.bottom = '5%';
        inputAre.style.width = '100%';
      }
    }
  }, [chatLog])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!responseFromAPI && inputPrompt.trim() !== "") {
      const loadingMessage = {
        chatPrompt: inputPrompt,
        botMessage: "Loading...",
      };
      setChatLog((prevChatLog) => [...prevChatLog, loadingMessage]);

      // @ts-ignore
      document.querySelector("textarea").blur();

      setIsButtonDisabled(true);
      try {
        const userId = localStorage.getItem("user_id");
        const token = localStorage.getItem("token");
        e.target.disabled = true;
        e.target.style.cursor = "wait";
        let payload;
        if (organizationId) {
          payload = {
            url: inputPrompt,
            userId,
            organizationId,
          };
        } else {
          payload = {
            url: inputPrompt,
            userId,
          };
        }

        const response = await axios.post(
          `${API_BASE_URL}/check-url?organizationId=${organizationId}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response);
        if (response.status === 201) {
          await fetchUserData();
          clearTextarea();
          const responseMessage = response.data.Link.responseMessage;

          const phishing = response.data.Link.phishing;
          console.log(response.data);

          setChatLog((prevChatLog) =>
            prevChatLog.map((message) =>
              message.botMessage === "Loading..."
                ? {
                  ...message,
                  botMessage: responseMessage,
                  phishing: phishing,
                }
                : message
            )
          );
        } else {
          const errorMessage = await response.text();
          setChatLog((prevChatLog) =>
            prevChatLog.map((responseMessage) =>
              responseMessage.botMessage === "Loading..."
                ? { ...responseMessage, botMessage: errorMessage }
                : responseMessage
            )
          );
        }
      } catch (error) {
        const errorMessage =
          error.response.data.error || error.response.data.message;
        setChatLog((prevChatLog) =>
          prevChatLog.map((message) =>
            message.botMessage === "Loading..."
              ? { ...message, botMessage: errorMessage }
              : message
          )
        );
        setErrorRedirect(errorMessage);
        setError(errorMessage);
        if (
          _error === "You are no longer part of the current organization" ||
          _error === "Token expired"
        ) {
          setShouldLogOut(true);
        }
      } finally {
        setIsButtonDisabled(false);
        setResponseFromAPI(false);
        e.target.disabled = false;
        e.target.style.cursor = "auto";
      }
    }
  };

  const updateUserDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        localStorage.setItem("plan", response.data.plan);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("subscription", response.data.subscription);
        localStorage.setItem(
          "max_team_members",
          response.data.max_team_members
        );

        if (response.data.billingOption) {
          localStorage.setItem("billingOption", response.data.billingOption);
        }

        if (response.data.organizationId) {
          localStorage.setItem(
            "organization_id",
            response.data.organizationId?.[0]
          );
        }
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );

        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const upgradeStatus = queryParams.get("upgrade");

    if (upgradeStatus === "success") {
      updateUserDetails();
    }
  }, [location.search]);

  const autoResizeTextarea = (e) => {
    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;

    const textareaHeight = textarea.offsetHeight;

    setTextareaHeight(textarea.offsetHeight);

    const remainingHeight = window.innerHeight - textareaHeight;

    const chatArea = chatAreaRef.current;

    // chatArea.style.height = '70%'
    chatArea.style.height = `${remainingHeight - 100 }px`;
  };

  useEffect(() => {
    // Adjust chatArea height whenever textareaHeight changes
    if (chatAreaRef.current) {
      const remainingHeight = window.innerHeight - textareaHeight;
      chatAreaRef.current.style.height = `${remainingHeight-150}px`;
    }
  }, [textareaHeight]); 

  useEffect(() => {
    const params = new URLSearchParams().toString();

    const refreshpage = async (type) => {
      try {
        const response = await refresh(localStorage.getItem("email"));
        const message = await localize(response);
        if (type === "add-team-protection" && message) {
          navigate("/add-team-workspace");
          return;
        } else if (type === "add-individual-protection" && message) {
          navigate("/dashboard");
          window.location.reload();
          return;
        }
      } catch (error) {
        console.log(error);
      }
    };
    console.log(searchParams);

    console.log(searchParams.get("add-team-protection"));
    if (searchParams.get("add-team-protection") === "true") {
      refreshpage("add-team-protection");
    } else if (searchParams.get("upgrade") === "add-individual-protection") {
      refreshpage("add-individual-protection");
    }
    if (chatLogEndRef.current) {
      // @ts-ignore
      chatLogEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }

    fetchUserData();
  }, [chatLog, navigate, searchParams]);

  function handleEnterUp(e) {
    e.preventDefault();

    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
    } else if (e.key === "Enter") {
      handleSubmit(e);
    }
  }
  function handleEnterDown(e) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
    }
  }

  const handleRefreshToken = async () => {
    try {
      localStorage.clear();
      window.location.href = "/";
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const extractReasons = (botMessage) => {
    const reasonStart = "because:";
    const reasonStartIndex = botMessage.indexOf(reasonStart);

    if (reasonStartIndex !== -1) {
      const reasonsText = botMessage.slice(reasonStartIndex + reasonStart.length).trim();

      const reasons = reasonsText
        .split("\n")
        .map((reason) => reason.trim())
        .filter((reason) => reason.length > 0);

      return reasons;
    }

    return [];
  };

  const extractRiskLevel = (botMessage) => {
    const riskLevelMatch = botMessage.match(/Risk Level: (\d+)%/);

    if (riskLevelMatch) {
      return parseInt(riskLevelMatch[1], 10);
    }

    return null;
  };

  return (
    <>
      {shouldLogOut && (
        <div
          className="position-absolute w-100 h-100  z-2 d-flex flex-column justify-content-center align-items-center"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <div
            className=" text-black d-flex flex-column justify-content-center align-items-center rounded text-center"
            style={{
              zIndex: "2",
              backgroundColor: "#bbff4b",
              width: "40%",
              height: "40%",
            }}
          >
            <div class="p-3">
              <p className="text-dark">
                You have been removed from the Current Organization's Account.
              </p>
              <p className="text-dark">
                To enjoy VerifiedX protection click <b>Continue</b> to log in to
                access your other account(s) or subscribe to one
              </p>
            </div>
            <button className="btn btn-dark" onClick={handleRefreshToken}>
              Continue
            </button>
          </div>
        </div>
      )}

      <header style={{ width: "100%" }}>
        <div
          className="menu"
          style={{
            position: "absolute",
            top: "0px",
            zIndex: "1",
          }}
        >
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#d9d9e3"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <h1>VerifiedX</h1>
      </header>

      {showMenu && (
        <nav>
          <div className="navItems">
            <NavContent
              chatLog={UserData}
              setChatLog={setChatLog}
              setShowMenu={setShowMenu}
            />
          </div>
        </nav>
      )}

      <aside className="sideMenu">
        <NavContent
          chatLog={UserData}
          setChatLog={setChatLog}
          setShowMenu={setShowMenu}
        />
      </aside>

      <section className="chatBox" onClick={() => setShowMenu(false)}>
        {isPlanNone && (
          <div
            className=" h-100"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <div
              className="h-100 w-100 d-flex flex-row justify-content-center align-items-center"
              style={{
                position: "absolute",
                backdropFilter: "blur(8px)",
                zIndex: "1",
                width: "10",
              }}
            ></div>
            <UpgradePlan />
          </div>
        )}
        {chatLog.length > 0 && !isPlanNone ? (
          <div className="chatLogWrapper" ref={chatAreaRef}>
            {chatLog.map((chat, idx) => {
              const isNoScam = chat.botMessage.includes("No Scam");
              const riskLevel = !isNoScam ? extractRiskLevel(chat.botMessage) : null;
              const reasons = !isNoScam ? extractReasons(chat.botMessage) : [];

              return (
                <div className="chatLog mb-4" style={{ width: "100%" }} key={idx}>
                  <div className="chatPromptMainContainer mb-2">
                    <Row style={{ maxWidth: "100%", display: "flex", flexDirection: "row-reverse" }}>
                      <Col xs={"auto"} style={{ width: "80px" }}>
                        <div className="userSVG">
                          <img
                            style={{ height: "100%", width: "100%", border: "2px solid #bbff4b" }}
                            src={AVATAR}
                            alt="User Avatar"
                            className="avatar"
                          />
                        </div>
                      </Col>
                      <Col
                        className="d-flex flex-column justify-content-center"
                        style={{
                          background: "linear-gradient(to bottom right, rgba(187, 255, 75, 0.2), rgba(187, 255, 75, 0.1))",
                          border: "1px solid rgba(187, 255, 75, 0.3)",
                          borderRadius: '15px',
                          maxWidth: '90%'
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Cont-regular",
                            overflowWrap: "break-word",
                            display: "inline-block",
                            textAlign: "end",
                            padding: "5px 0",
                          }}
                          id="chatPrompt"
                        >
                          {chat.chatPrompt}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="botMessageMainContainer">
                    <div className="botMessageWrapper">
                      <div className="row">
                        <div className="col-auto">
                          <img
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "50%",
                            }}
                            src="/64x64.svg"
                            alt="verifiedX"
                          />
                        </div>
                        {chat.botMessage === "Loading..." ? (
                          <Col className="d-flex flex-column ">
                            <Loading />
                          </Col>
                        ) : (
                          <div className="col">
                            <div
                              className="d-flex flex-column p-3"
                              style={{
                                background: "linear-gradient(to bottom right, #1a1a1a, #2a2a2a)",
                                border: "1px solid rgba(255, 255, 255, 0.1)",
                                color: "#fff",
                                width: isNoScam ? "fit-content" : "95%",
                                // maxWidth: '60%',
                                borderRadius: '15px'
                              }}
                            >
                              {isNoScam ? (
                                <div className="text-white text-center" >
                                  <strong>No Scam</strong>
                                </div>
                              ) : (
                                <>
                                  <div className="mb-3">
                                    <div className="d-flex justify-content-between" >
                                      <span style={{ color: 'lightgray' }}>Risk Level</span>
                                      <span className="font-weight-bold text-danger">
                                        {riskLevel !== null ? `${riskLevel}%` : 'Unknown'}
                                      </span>
                                    </div>
                                    {riskLevel !== null && (
                                      <div className="progress" style={{ height: "8px", background: '#ffffff1a' }}>
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: `${riskLevel}%`, 
                                          background: 'linear-gradient(to right, #F59E0B, #EF4444)',
                                          height: '100%',
                                          borderRadius: '9999px' ,
                                          transition: 'all 0.3s ease' }}
                                          aria-valuenow={riskLevel}
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    )}
                                  </div>

                                  <div className="text-white">
                                    <strong>Very likely a scam/phishing because:</strong>
                                    <ul className="mt-2 mx-4">
                                      {reasons.map((reason, idx) => (
                                        <li key={idx}>{reason}</li>
                                      ))}
                                    </ul>
                                  </div>
                                  <div style={{
                                    fontStyle: 'italic',
                                    color: 'lightgray'
                                  }}>
                                    Block and report the sender to avoid getting scammed.
                                  </div>
                                </>
                              )}
                            </div>
                          </div>)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )
          : (
            <IntroSection />
          )}

        {!isPlanNone && (
          <form className="input-main-container" onSubmit={handleSubmit}>
            <div className="inputPrompttTextarea" ref={inputAreaRef}>
              <textarea
                ref={textareaRef}
                name="inputPrompt"
                id="inputPrompt"
                className="input"
                placeholder="Just paste text and links here..."
                type="text"
                rows={1}
                value={inputPrompt}
                onChange={(e) => setInputPrompt(e.target.value)}
                onInput={autoResizeTextarea}
                autoFocus={false}
                autoComplete="off"
                spellCheck="false"
                onKeyUp={handleEnterUp}
                onKeyDown={handleEnterDown}
              />
              <button
                aria-label="form submit"
                type="submit"
                className="submit-button-textarea"
                disabled={isButtonDisabled}
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    borderRadius: "100%",
                    height: "30px",
                    width: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: '#bbff4b'
                  }}
                >
                  <FiSend
                    size={18}
                    style={{
                      color: "#000",
                      fontSize: "2rem",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </button>
            </div>
          </form>
        )}
      </section>
    </>
  );
};

export default Home;
